@import "./Colors.scss";

.TrainingToolDetailConvo h4,
.SpravaOdpovediDetail h4 {
  //border-top: 1px solid $darkColor;
  margin-bottom: 20px;
}

.SpravaOdpovediDetail-wrap {
  text-align: left;
  display: inline-block;
  vertical-align: top;
  transition: width 2s;
}

.chatContainer {
/*  width: 360px;
  margin: auto;
  box-shadow: 2px 10px 40px rgba(22, 20, 19, 0.4);
  border-radius: 25px;
  min-height: 400px;*/
  margin-bottom: 25px;
}

.chatHeader {
  position: relative;
  min-height: 50px;
  text-align: center;
  border-bottom: 1px solid $darkColor;
}

.chatLogo {
  width: 80px;
  border-radius: 40px;
  position: absolute;
  top: -40px;
  left: 15px;
  border: 2px solid #fff;
}

.chatTitle {
  padding: 15px;
}

.dialogDetailMessage {
  clear: both;
  /* float: left; */
  padding: 6px 10px 7px;
  border-radius: 20px 20px 20px 0;
  background: #fff;
  margin: 15px 0;
  font-size: 14px;
  line-height: 1.4;
  //margin-left: 35px;
  position: relative;
  border: 1px solid $grey;
  width: fit-content;
  max-width: 300px;
}

.dialogDetailText{
  box-shadow: 3px 5px 2px rgba(22, 20, 19, 0.4);
}

.dialogDetailText > div {
  padding: 0;
}

.dialogDetailMessage p {
  margin: 5px 0;
}

.chatAvatar {
  display: none;
}

/*
.chatAvatar {
    position: absolute;
    z-index: 101;
    bottom: -15px;
    left: -35px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border-radius: 48px;
    border: 0.5px solid#297321;
    border-radius: 15px;
    background-color: $white;
}

.chatAvatar.noOutline {
    border: 0;
}

.chatAvatar.noOutline img {
    width: 28px;
}

.chatAvatar img {
  width: 23px;
  margin: auto;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
*//*
.chatBody {
  padding: 0 20px 25px 20px;
}
*/
.dialogDetailOption {
  display: block;
  font-size: 14px;
  font-weight: 100;
  padding: 6px 10px;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $white;
  margin: 8px 0 0 0;
  cursor: pointer;
  width: fit-content;
  height: auto;
  text-align: left;
  border: 1px solid#58A813;
}

.dialogDetailOption p {
  margin: 0;
}

.dialogDetailOptions {
  //padding: 0 35px;
}
