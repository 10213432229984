$darkColor: #323232;
$darkGreyColor: #6f6f6e;
$lightGeryColor: #ebebeb;
$lightGeryColor2: rgb(189, 189, 189);
$yellowColorOrig: #f9b232;
$lightYellowColorNoOrig: #f9d732;
$blueColor: #009ee2;
$darkGreenColor: #006937;
$darkGreenColor2: #2E6D1A;
$lightGreenColor: #58a813;
$lightGreenColor2: #dff7bd;
$lightGreenColor3: #52d869;
$lightGreenColor4: #3F8B18;
$eww: #1BAE98;
$whiteColor: #fff;
$whiteColor2: #f7f7f7;
$transparentBlack: rgba(50, 50, 50, 0.5);
$red: red;
$white: #fff;
$grey: #eee;
$GeryColor2: #b9b8b8;