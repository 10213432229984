@import "./Colors.scss";

.SpravaOdpovediPrehled-wrap .col,
.SpravaOdpovediPrehled-wrap .col-1,
.SpravaOdpovediPrehled-wrap .col-2,
.SpravaOdpovediPrehled-wrap .col-3,
.SpravaOdpovediPrehled-wrap .col-4,
.SpravaOdpovediPrehled-wrap .col-5,
.SpravaOdpovediPrehled-wrap .col-6,
.SpravaOdpovediPrehled-wrap .col-7,
.SpravaOdpovediPrehled-wrap .col-8,
.SpravaOdpovediPrehled-wrap .col-9,
.SpravaOdpovediPrehled-wrap .col-10,
.SpravaOdpovediPrehled-wrap .col-11,
.SpravaOdpovediPrehled-wrap .col-12{
    text-align: left;
    word-break: break-word;
    margin: auto 0;
}


.SpravaOdpovediPrehled-wrap .SO-short-title{
    transition: 2s;
}
