@import "./Colors.scss";

.SpravaOdpovediPridatKomentar h4 {
  //border-top: 1px solid $darkColor;
  margin-bottom: 30px;
}

.SpravaOdpovediPridatKomentar h5 {
  border-top: 1px solid $darkColor;
}

.SpravaOdpovediKomentar-wrap {
  //width: 50%;
  display: inline-block;
  vertical-align: top;
}

.SpravaOdpovediKomentar-wrap .row.comment {
  margin: 15px 0;
}

.SpravaOdpovediKomentar-wrap .row.comment .commentAuthor {
  padding: 0 10px 15px 10px;
  margin: auto 0 0 0;
}

.SpravaOdpovediKomentar-wrap .comment .commentText {
  line-height: 1.4;
  position: relative;
  border: 1px solid $grey;
  text-align: left;
}

.SpravaOdpovediKomentar-wrap .close-wrap {
  vertical-align: middle;
  text-align: center;
  margin: auto;
  display: block;
  width: 50px;
  margin-right: -10px;
}

.SpravaOdpovediKomentar-wrap .close {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  display: block;
}

#root .close.red:hover,
#root .close.red:active,
#root .close.red:focus {
  box-shadow: 0 0 0 0.2rem $red;
}

#root .close.green:hover,
#root .close.green:active,
#root .close.green:focus {
  box-shadow: 0 0 0 0.2rem $lightGreenColor;
}

.close.red {
  color: $red;
}

.close.green {
  color: $lightGreenColor;
}

.SpravaOdpovediKomentare {
  max-height: 700px;
  overflow: auto;
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: block;
  width: 100%;
  //height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;
  margin: 0;
}

html .rdw-editor-main p {
  padding: 0;
}

html .rdw-option-wrapper:hover {
  box-shadow: 1px 1px 3px #4a3a3a;
}

html .rdw-option-active,
.rdw-option-wrapper:active {
  box-shadow: inset 0px 0px 5px #7d7777;
}

html .rdw-option-wrapper {
  border: 1px solid #f1f1f1;
  padding: 5px;
  min-width: 30px;
  height: 30px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.SpravaOdpovediKomentar-wrap .messageBubble {
  position: relative;
  padding-left: 25px;
  padding-right: 10px;
}

.SpravaOdpovediKomentar-wrap .commentedByMe .messageBubble {
  padding-right: 25px;
  padding-left: 10px;
}

.SpravaOdpovediKomentar-wrap .messageTime {
  color: $darkGreyColor;
  text-align: left;
  margin-left: 15px;
}

.SpravaOdpovediKomentar-wrap .messageAuthor {
  color: $darkGreyColor;
  text-align: right;
  position: absolute;
  bottom: 0px;
  right: 15px;
}

.SpravaOdpovediKomentar-wrap .commentedByMe .messageAuthor {
  text-align: left;
  position: absolute;
  bottom: 0px;
  left: 15px;
}

.SpravaOdpovediKomentar-wrap .commentedByMe .messageTime {
  text-align: right;
  margin-left: 0px;
  margin-right: 15px;
}

.SpravaOdpovediKomentar-wrap .comment.commentedByMe .commentText {
  border-radius: 20px 20px 20px 0;
}

.commentAuthor .chatAvatar {
  bottom: 5px;
  left: -10px;
}
.commentedByMe .commentAuthor .chatAvatar {
  left: 30px;
}

html .rdw-inline-wrapper > div:nth-child(5),
html .rdw-inline-wrapper > div:nth-child(6),
html .rdw-inline-wrapper > div:nth-child(7),
html .rdw-fontfamily-wrapper,
html .rdw-remove-wrapper,
html .rdw-embedded-wrapper,
html .rdw-block-wrapper {
  display: none;
}

.demo-editor div{
  margin: 0;
}

.SpravaOdpovediKomentar-wrap{
  margin-top: 15px;
  padding-left: 20px;
}