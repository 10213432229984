@import "./Colors.scss";

.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #fcfff1);
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.center {
  text-align: center;
}

.export-excel-button,
.custom-file-input::before {
  content: "Vložte soubor typu JSON";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 16px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 20px;
  width: 100%;
  text-align: center;
}

html .custom-file-input {
  opacity: 1;
}

html .custom-file-input {
  color: transparent;
  height: 60px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.data-import {
  padding: 0 30px;
}

.data-import .row{
  margin-bottom: 15px;
}