@import "./Colors.scss";

.SpravaOdpovediNewDialogListRow,
.SpravaOdpovediPrehledRow{
    font-size: 0.95rem;
}

.SpravaOdpovediNewDialogListRow .row_prehled .btn,
.SpravaOdpovediPrehledRow .row_prehled .btn{
    width: 100%;
    margin: 4px 0;
}

.SpravaOdpovediNewDialogListRow,
.SpravaOdpovediPrehledRow{
    padding: 10px;
}

.SpravaOdpovediNewDialogListRow > .row,
.SpravaOdpovediPrehledRow > .row{
    padding: 0 10px;
}

.SpravaOdpovediNewDialogListRow:nth-child(odd),
.SpravaOdpovediPrehledRow:nth-child(odd){
    background-color: $whiteColor2;
}

.SpravaOdpovediNewDialogListRow:nth-child(even),
.SpravaOdpovediPrehledRow:nth-child(even){
    background-color: $whiteColor;
}

.SpravaOdpovediNewDialogListRow .row-detail,
.SpravaOdpovediPrehledRow .row-detail{
    transition: 1s;
    height: 0;
}