@import "./Colors.scss";

.App {
  text-align: center;
  background: url(/Depositphotos_311725762_xl-2015_web.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-color: $whiteColor;
  padding-top: 50px;
  min-height: 100%;
  height: -webkit-fill-available;
}

.App.loggedIn {
  height: auto;
}

.App.loggedIn .App-body {
  min-height: 81vh;
}

.App.loggedIn .App-footer {
  margin-top: 0;
}

.App > .container {
  min-height: 100%;
  padding: 25px 0;
}

html,
html body {
  height: -webkit-fill-available;
  background-color: $whiteColor;
  color: $darkColor;
}

.btn {
  margin: 15px 0;
  background-color: $lightGreenColor2;
  color: $darkColor;
  border-color: $darkColor;
  border: 0px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#root .btn:active,
#root .btn:focus,
#root .btn:hover {
  background-color: $lightGreenColor;
  color: $darkColor;
  border-color: $darkColor;
}

#root :focus,
#root .btn:focus {
  box-shadow: 0 0 0 0.1rem $transparentBlack;
}

img {
  width: 100%;
}

#root {
  height: -webkit-fill-available;
}

.App-header {
  text-align: right;
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
  padding: 0 25px;
  color: $lightGeryColor;
  top: 0;
  z-index: 9;
}

.App-header .user-profile-short,
.App-header .btn {
  margin-right: 15px;
}

.App-header .user-profile-short {
  display: inline-block;
  color: $darkColor;
}

.menu {
  display: inline-block;
}

#logo-header {
  width: 75px;
  position: absolute;
  bottom: 0;
  left: 5%;
  margin: auto;
  top: 0;
}

.main-title {
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: fit-content;
}

.main-title-empty {
  height: 68px;
  color: rgba(0, 0, 0, 0.7);
}

.Options-btn,
.ProjectSelect,
.Logout-wrap,
.Profile-wrap {
  display: inline-block;
}

.menu .btn,
html .ProjectSelect select,
html .ProjectSelect div,
html .App-header .user-profile-short,
html .Options-btn,
html .Profile-wrap {
  margin-right: 15px;
  border: 0;
}

.ProjectSelect select,
.ProjectSelect div,
.App-header .user-profile-short,
.App-header .btn {
  background-color: $whiteColor;
  color: $darkColor;
  opacity: 0.95;
}

.ProjectSelect select,
.ProjectSelect div,
.App-header .user-profile-short {
  margin: 15px 0;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 0px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.App-body {
  background-color: $whiteColor;
  margin: 0 auto;
  //border-radius: 5px;
  padding: 20px 15px;
}

.App-footer {
  background-color: $darkColor;
  color: $lightGeryColor;
  margin-top: -38px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: $blueColor;
}

.App-footer {
  padding: 10px;
}

html #root :focus, html #root .btn:focus {
  box-shadow: initial;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
  position: initial;
}

#root .Mui-focused{
  color: $lightGreenColor4;
}

#root .Mui-focused fieldset{
  border-color: $lightGreenColor4;
}

#root .MuiButton-containedPrimary {
  background-color: $lightGreenColor;
}

#root .MuiButton-containedPrimary:hover {
  background-color: $lightGreenColor4;
}

.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
}

.main-title-user-avatar {
  display: inline-block;
  outline: none;
  vertical-align: middle;
  border-radius: 25px;
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  background-color: #ebebeb;
  text-align: center;
}
.main-title-user-avatar-first, .main-title-user-avatar-last {
  display: inline-block;
  line-height: 50px;
  font-size: x-large;
  text-align: center;
}

.MuiPopover-root .MuiMenu-paper {
  margin-top: 46px;
}
body {
  padding: 0 !important;
  overflow: auto !important;
}