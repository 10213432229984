@import "./Colors.scss";

.Search-wrap {
  padding: 0 25px;
}

.SpravaOdpovedi-wrap .title-main {
  margin-bottom: 15px;
}

.Search-wrap .Search-checkboxes label {
  text-align: left;
  margin-left: 15px;
}
.Search-wrap .Search-checkboxes .Search-checkboxes-title {
  margin-bottom: 5px;
}

.Search-wrap .Search-checkboxes {
  margin-bottom: 15px;
}

.SpravaOdpovedi-wrap {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.requestStatus-simple-select .MuiFormControl-root {
  min-width: 150px;
}

.requestStatus-simple-select {
  padding-bottom: 40px;
}

.SpravaOdpovedi-renderPrehled {
  position: relative;
}

.SpravaOdpovedi-renderSidebar {
  position: absolute;
  right: -290px;
  background: #fff;
  max-width: 270px;
  text-align: left;
  padding: 10px;
  top: -30px;
}

.SpravaOdpovedi-renderSidebar .bold {
  font-weight: 500;
}

.projectName {
  text-align: left;
  padding: 0 0 20px 15px;
}

.switchMenu-row {
  text-align: center;
  padding-bottom: 8px;
}

.MuiDialogTitle-root button {
  background-color: $GeryColor2;
  border-width: 1px 1px 1px 1px;
  bottom: -1px;
  font-size: 19px;
  padding: 5px 30px;
  color: #fff;
  border-radius: 25px 25px 25px 25px;
  margin-bottom: 3px;
  margin-right: 15px;
}

.MuiDialogTitle-root .active-platform button {
  background-color: $lightGreenColor4;
  border-color: $lightGreenColor4;
  //border-radius: 0px 25px 0 0;
}

.platform-control-row {
  border-bottom: 1px solid #3f8b18;
  padding-top: 10px;
}

.platform-control-col {
  display: inline-block;
  position: relative;
}

.notification-icon {
  position: absolute;
  right: 10px;
  top: -6px;
  border: 1px solid $darkColor;
  border-radius: 25px;
  width: 20px;
  height: 20px;
  text-align: center;
  background-color: $yellowColorOrig;
  color: $darkColor;
}

.textYellow {
  color: $yellowColorOrig;
}

.textGreen {
  color: $lightGreenColor4;
}

.platform-control-row-block-2 h4 {
  padding-left: 40px;
}

.platform-control-row-block-2 span {
  font-size: 1.5rem;
}

.platform-control-row-block-1,
.platform-control-row-block-2,
.platform-control-row-block-2 h4,
.platform-control-row-block-2 span {
  display: inline-block;
}

.platform-control-row-block-1 {
  min-width: 33.333333%;
}

.col-filters {
  padding-right: 25px;
}

.MuiDialog-container .SpravaOdpovediNewDialogList .MuiButtonBase-root,
.MuiDialog-container .row-detail .MuiButton-containedPrimary {
  background-color: $lightGreenColor4;
  margin-top: 10px;
  margin-bottom: 25px;
}

.MuiDialog-container .MuiButtonBase-root .MuiButton-label {
  color: $whiteColor;
}

.MuiDialog-container .MuiDialogActions-root .MuiButton-label {
  color: $lightGreenColor4;
}

.SpravaOdpovediHistory {
  margin-top: 20px;
  padding: 20px;
  background-color: $grey;
  text-align: center;
}
/*
.SpravaOdpovediHistory h4{
  padding: 20px 0 10px 20px;
  border-width: 1px 1px 0 1px;
  border-color: $lightGreenColor4;
  border-style: solid;
  margin: 0;
}
*/
.SpravaOdpovediHistory .resolved-comments{
  //border-width: ;
  //border-color: $lightGreenColor4;
  //border-style: solid;
  //padding: 20px 0 20px 0;
  border-width: 1px 0px 0px 0px;
  border-color: $darkColor;
  border-style: solid;
  margin-top: 15px;
}

.no-history {
  margin-top: 20px;
}

.SpravaOdpovediKomentar-wrap form {
  text-align: right;
}

.requestStatus-simple-select {
  margin-left: 10px;
}

.renderTopRow .button{
  text-align: right;
}

html .MuiCheckbox-colorSecondary.Mui-checked {
  color: $lightGreenColor4;
}
