#top-menu-right.mobile {
  position: relative;
}
#top-menu-right.mobile .menu {
  position: absolute;
  top: 68px;
  background-color: rgba(0, 0, 0, 0.7);
  right: -25px;
  text-align: center;
}

.mobile .Logout-wrap{
    width: 100%;
}

#top-menu-right.mobile > .menu button {
  display: block;
  border: 0;
  text-align: center;
  margin: auto;
  font-size: 1rem;
  font-weight: 300;
  width: 100%;
  padding: 15px 25px;
}
/*
#top-menu-right.mobile > .menu > div {
  padding: 0;
  border: 0;
  margin: 0;
}
*/