@import "./Colors.scss";

.ProjectSelect select,
.ProjectSelect div{
    display: inline-block;
    margin-right: 15px;
    color: $darkColor;
}

.ProjectSelect select{
    max-width: max-content;
}
