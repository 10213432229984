@import "./Colors.scss";

.SpravaOdpovediNewDialogList h4 {
  border-top: 1px solid $darkColor;
  margin-bottom: 30px;
}

.SpravaOdpovediNewDialogList h5 {
  border-top: 1px solid $darkColor;
}

.SpravaOdpovediNewDialogList {
  //width: 50%;
  text-align: left;
  display: inline-block;
  vertical-align: top;
}

.SpravaOdpovediNewDialogList .row.comment {
  margin: 15px 0;
}

.SpravaOdpovediNewDialogList .row.comment .commentAuthor {
  padding: 0 10px 15px 10px;
  margin: auto 0 0 0;
  position: relative;
}

.SpravaOdpovediNewDialogList .comment .commentText {
  clear: both;
  padding: 6px 10px 7px;
  border-radius: 20px 20px 0px 20px;
  background: $white;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  border: 1px solid $grey;
  text-align: left;
}

.SpravaOdpovediNewDialogListRow .commentText.dialogNew {
  text-overflow: ellipsis;
  max-height: 55px;
  overflow: hidden;
}

.SpravaOdpovediNewDialogList .close-wrap {
  vertical-align: middle;
  text-align: center;
  margin: auto;
  display: block;
  width: 50px;
  margin-right: -10px;
}

.SpravaOdpovediNewDialogList .close {
  border-radius: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  display: block;
}

#root .close.red:hover,
#root .close.red:active,
#root .close.red:focus {
  box-shadow: 0 0 0 0.2rem $red;
}

#root .close.green:hover,
#root .close.green:active,
#root .close.green:focus {
  box-shadow: 0 0 0 0.2rem $lightGreenColor;
}

.close.red {
  color: $red;
}

.close.green {
  color: $lightGreenColor;
}

.SpravaOdpovediKomentare {
  max-height: 700px;
  overflow: auto;
}

.rdw-editor-main {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: block;
  width: 100%;
  //height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;
  margin: 0;
}

html .rdw-editor-main p {
  padding: 0;
}

html .rdw-option-wrapper:hover {
  box-shadow: 1px 1px 3px #4a3a3a;
}

html .rdw-option-active,
.rdw-option-wrapper:active {
  box-shadow: inset 0px 0px 5px #7d7777;
}

html .rdw-option-wrapper {
  border: 1px solid #f1f1f1;
  padding: 5px;
  min-width: 30px;
  height: 30px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}

.SpravaOdpovediNewDialogList .messageBubble {
  position: relative;
  padding-left: 25px;
  padding-right: 10px;
}

.SpravaOdpovediNewDialogList .commentedByMe .messageBubble {
  padding-right: 25px;
  padding-left: 10px;
}

.SpravaOdpovediNewDialogList .messageTime {
  color: $darkGreyColor;
  text-align: left;
  margin-left: 15px;
}

.SpravaOdpovediNewDialogList .messageAuthor {
  color: $darkGreyColor;
  text-align: right;
  position: absolute;
  bottom: 0px;
  right: 15px;
}

.SpravaOdpovediNewDialogList .commentedByMe .messageAuthor {
  text-align: left;
  position: absolute;
  bottom: 0px;
  left: 15px;
}

.SpravaOdpovediNewDialogList .commentedByMe .messageTime {
  text-align: right;
  margin-left: 0px;
  margin-right: 15px;
}

.SpravaOdpovediNewDialogList .comment.commentedByMe .commentText {
  border-radius: 20px 20px 20px 0;
}

.commentAuthor .chatAvatar {
  bottom: 5px;
  left: -10px;
}
.commentedByMe .commentAuthor .chatAvatar {
  left: 30px;
}

html .rdw-inline-wrapper > div:nth-child(5),
html .rdw-inline-wrapper > div:nth-child(6),
html .rdw-inline-wrapper > div:nth-child(7),
html .rdw-fontfamily-wrapper,
html .rdw-remove-wrapper,
html .rdw-embedded-wrapper,
html .rdw-block-wrapper {
  display: none;
}

.rdw-editor-main {
  font-weight: initial;
}

.SpravaOdpovediNewDialogListRow:nth-child(odd) {
  background-color: $whiteColor2;
}

.SpravaOdpovediNewDialogListRow:nth-child(even) {
  background-color: $whiteColor;
}

.submit-new-dialog {
  margin-top: 15px;
}
/*
.SpravaOdpovediNewDialogList .actions {
  border-top: 1px solid $lightGreenColor4;
  padding-top: 15px;
  margin-top: 15px;
}
*/
.SpravaOdpovediNewDialogList .actions .col {
  margin: auto;
  text-align: center;
}

.SpravaOdpovediNewDialogList .actions .col .red {
  background-color: $red;
}

.SpravaOdpovediNedDialogEditDetail-wrap .row {
  margin-bottom: 15px;
}

.SpravaOdpovediNedDialogEditDetail-wrap .row .MuiInputLabel-formControl,
.SpravaOdpovediNedDialogEditDetail-wrap .row .MuiInputBase-input {
  color: $darkColor;
}

.SpravaOdpovediNewDialogCreate-wrap .note {
  font-size: 0.8rem;
  font-style: italic;
  text-align: left;
  margin-top: 5px;
  color: $GeryColor2;
}

.SpravaOdpovediNewDialogCreate-wrap .createQuestions {
  margin-top: 25px;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.rdw-editor-main {
  min-height: 150px;
}

.SpravaOdpovediNedDialogEditDetail-wrap {
  text-align: right;
}

.showDataRow p {
  min-height: 15px;
}

.showDataRow {
  text-align: left;
  border: 1px solid $grey;
  border-radius: 2px;
  padding: 10px 15px;
}

.SpravaOdpovediNedDialogEditDetail-wrap h4 {
  text-align: left;
  margin: 0;
  border-top: 0;
}

.createAnswers h4,
.createQuestions h4 {
  margin-top: 50px;
}

.SpravaOdpovediNewDialogList .mainTitle {
  margin-bottom: 40px;
  text-align: left;
  font-weight: 400;
}

.SpravaOdpovediNewDialogList
  .SpravaOdpovediNedDialogEditDetail-wrap
  .commentsTitle,
.SpravaOdpovediNewDialogList .actionsTitle h4 {
  margin-top: 40px;
  border-top: 1px solid #323232;
  margin-bottom: 30px;
  font-weight: 400;
}

.SpravaOdpovediNewDialogList
  .SpravaOdpovediNedDialogEditDetail-wrap
  .commentsTitle h4{
  font-weight: 400;
}

.MuiDialog-paperWidthLg {
  min-width: 1000px;
}